/**
 * @ABS === ABSOLUTE
 */

import * as routes from "./routes";

export const ABS_ACCOUNT_LOGIN = `/${routes.ACCOUNT}/${routes.LOGIN}`;
export const ABS_FORGOT_PASSWORD = `/${routes.ACCOUNT}/${routes.FORGOT_PASSWORD}`;
export const ABS_RESET_PASSWORD = `/${routes.ACCOUNT}/${routes.RESET_PASSWORD}`;

export const ABS_QR_CODE = `/${routes.QR_CODE}`;
export const ABS_PROFILE = `/${routes.PROFILE}`;
export const ABS_PROFILE_EDIT = `/${routes.PROFILE}/${routes.EDIT}`;
export const ABS_PROFILE_CHANGE_PASS = `/${routes.PROFILE}/${routes.CHANGE_PASSWORD}`;

export const ABS_DASHBOARD = `/${routes.DASHBOARD}`;
export const ABS_DINEIN = `/${routes.DINEIN_TUTORIAL}`;

export const ABS_ADD_POPUP = `/${routes.ADDING_POPUP}`;
export const ABS_SEGMENT = `/${routes.SEGMENT}`;

export const ABS_VERSION = `/${routes.VERSION}`;
export const ABS_BUTTOM_MENU = `/${routes.BUTTOM_MENU}`;

export const ABS_GENERIC = `/${routes.GENERIC}`;

export const ABS_LOCATION = `/${routes.LOCATION}`;

export const ABS_FEEDBACK = `/${routes.FEEDBACK}`;

export const ABS_USER = `/${routes.USER}`;
export const ABS_USER_DETAILS = `/${routes.USER}/${routes.DETAILS}`;
export const ABS_USER_EDIT = `/${routes.USER}/${routes.EDIT}`;

export const ABS_REPORT_ANALYTICS = `/${routes.REPORT_ANALYTICS}`;

export const ABS_MENU = `/${routes.MENU}`;
export const ABS_MENU_DETAILS = `/${routes.MENU}/${routes.DETAILS}`;
export const ABS_ITEM_DETAILS = (id) => `/${routes.MENU}/${id}`;
export const ABS_CUSTOMIZATION_EDIT = (id) =>
  `/${routes.MENU}//${routes.EDIT}/${id}`;
export const ABS_MENU_EDIT = `/${routes.MENU}/${routes.EDIT}`;

export const ABS_ORDERS = `/${routes.ORDERS}`;
export const ABS_ORDERS_DETAILS = `/${routes.ORDERS}/${routes.DETAILS}`;

export const ABS_GLOBAL_PAYMENTS = `/${routes.GLOBAL_PAYMENTS}`;
export const ABS_PAYMENTS = `/${routes.PAYMENTS}`;
export const ABS_PAYMENTS_DETAILS = `/${routes.PAYMENTS}/${routes.DETAILS}`;

export const ABS_PROMO_OFFER = `/${routes.PROMO_OFFER}`;
export const ABS_PROMO_OFFER_ADD = `/${routes.PROMO_OFFER}/${routes.ADD}`;
export const ABS_GENERAL_PROMO_OFFER = `/${routes.PROMO_OFFER}/general`;
export const ABS_GENERAL_PROMO_OFFER_WITH_ID = (id) =>
  `/${routes.PROMO_OFFER}/${id}`;
export const ABS_GENERAL_PROMO_OFFER_ADD = `/${routes.PROMO_OFFER}/general/${routes.ADD}`;
export const ABS_PROMO_OFFER_EDIT = `/${routes.PROMO_OFFER}/${routes.EDIT}`;
export const ABS_PROMO_OFFER_DETAILS = `/${routes.PROMO_OFFER}/${routes.DETAILS}`;

export const ABS_LOYALITY = `/${routes.LOYALITY}`;
export const ABS_LOYALITY_ADD = `/${routes.LOYALITY}/${routes.ADD}`;
export const ABS_LOYALITY_EDIT = `/${routes.LOYALITY}/${routes.EDIT}`;
export const ABS_LOYALITY_DETAILS = `/${routes.LOYALITY}/${routes.DETAILS}`;

export const ABS_GENERAL_SETTING = `/${routes.GENERAL_SETTING}`;

export const ABS_PROMOS = `/${routes.PROMOS}`;
export const ABS_COUPONS = `/${routes.COUPONS}`;

export const ABS_EVENT = `/${routes.EVENT}`;
export const ABS_EVENT_ADD = `/${routes.EVENT}/${routes.ADD}`;
export const ABS_EVENT_EDIT = `/${routes.EVENT}/${routes.EDIT}`;

export const ABS_PARTNERSHIP = `/${routes.PARTNERSHIP}`;
export const ABS_PARTNERSHIP_ADD = `/${routes.PARTNERSHIP}/${routes.ADD}`;
export const ABS_PARTNERSHIP_EDIT = `/${routes.PARTNERSHIP}/${routes.EDIT}`;

export const ABS_HOME = `/${routes.HOME}`;

export const ABS_ACTIVITY_LOG = `/${routes.ACTIVITY_LOG}`;
export const ABS_ACTIVITY_LOG_DETAILS = `/${routes.ACTIVITY_LOG}/${routes.DETAILS}`;

export const ABS_DRIVER = `/${routes.DRIVER}`;
export const ABS_DRIVER_ADD = `/${routes.DRIVER}/${routes.ADD}`;
export const ABS_DRIVER_EDIT = `/${routes.DRIVER}/${routes.EDIT}`;
export const ABS_DRIVER_DETAILS = `/${routes.DRIVER}/${routes.DETAILS}`;

export const ABS_STAMPS = `/${routes.STAMPS}`;
export const ABS_STAMPS_ADD = `/${routes.STAMPS}/${routes.ADD}`;
export const ABS_STAMPS_EDIT = `/${routes.STAMPS}/${routes.EDIT}`;

export const ABS_RECOMMENDATION = `/${routes.RECOMMENDATION}`;
export const ABS_RECOMMENDATION_ADD = `/${routes.RECOMMENDATION}/${routes.ADD}`;
export const ABS_RECOMMENDATION_EDIT = `/${routes.RECOMMENDATION}/${routes.EDIT}`;

export const ABS_MAY_YOU_ALSO_LIKE = `/${routes.MAY_YOU_LIKE}`;
export const ABS_MAY_YOU_ALSO_LIKE_ADD = `/${routes.MAY_YOU_LIKE}/${routes.ADD}`;
export const ABS_MAY_YOU_ALSO_LIKE_EDIT = `/${routes.MAY_YOU_LIKE}/${routes.EDIT}`;

export const ABS_BANNERS = `/${routes.BANNERS}`;
export const ABS_BANNERS_ADD = `/${routes.BANNERS}/${routes.ADD}`;
export const ABS_BANNERS_EDIT = `/${routes.BANNERS}/${routes.EDIT}`;
export const ABS_BANNERS_DETAILS = `/${routes.BANNERS}/${routes.DETAILS}`;

export const ABS_RESTRO = `/${routes.RESTRO}`;
export const ABS_RESTRO_ADD = `/${routes.RESTRO}/${routes.ADD}`;
export const ABS_RESTRO_EDIT = `/${routes.RESTRO}/${routes.EDIT}`;
export const ABS_RESTRO_BULK_UPDATE = `/${routes.RESTRO}/${routes.BULK_UPDATE}`;
export const ABS_RESTRO_DETAILS = `/${routes.RESTRO}/${routes.DETAILS}`;

export const ABS_STANDARD_NOTIFICATION = `/${routes.STANDARD_NOTIFICATION}`;
export const ABS_STANDARD_NOTIFICATION_EDIT = `/${routes.STANDARD_NOTIFICATION}/${routes.EDIT}`;
export const ABS_STANDARD_NOTIFICATION_DETAILS = `/${routes.STANDARD_NOTIFICATION}/${routes.DETAILS}`;

export const ABS_NOTIFICATION = `/${routes.NOTIFICATION}`;
export const ABS_NOTIFICATION_ADD = `/${routes.NOTIFICATION}/${routes.ADD}`;
export const ABS_NOTIFICATION_EDIT = `/${routes.NOTIFICATION}/${routes.EDIT}`;
export const ABS_NOTIFICATION_DETAILS = `/${routes.NOTIFICATION}/${routes.DETAILS}`;

export const ABS_CMS = `/${routes.CMS}`;
export const ABS_TERM_CONDITIONS = `/${routes.TERM_CONDITIONS}`;
export const ABS_DRIVER_TERM_CONDITIONS = `/${routes.DRIVER_TERM}`;
export const ABS_DRIVER_PRIVACY = `/${routes.DRIVER_PRIVACY}`;
export const ABS_CANCELATION_POLICY = `/${routes.CANCELLATIONPOLICY}`;
export const ABS_OUR_VISION = `/${routes.TERM_CONDITIONS}`;
export const ABS_PRIVACY_POLICY = `/${routes.PRIVACY_POLICY}`;
export const ABS_ABOUT_US = `/${routes.ABOUT_US}`;
export const ABS_FAQ = `/${routes.FAQ}`;
export const ABS_SUPPORT = `/${routes.SUPPORT}`;

export const ABS_ROLES_ACCESS = `/${routes.ROLES_ACCESS}`;

export const ABS_ROLES = `/${routes.ROLES_ACCESS}/${routes.ROLES}`;
export const ABS_ROLES_ADD = `/${routes.ROLES_ACCESS}/${routes.ROLES}/${routes.ADD}`;
export const ABS_ROLES_EDIT = `/${routes.ROLES_ACCESS}/${routes.ROLES}/${routes.EDIT}`;
export const ABS_ROLES_DETAILS = `/${routes.ROLES_ACCESS}/${routes.ROLES}/${routes.DETAILS}`;

export const ABS_SUB_ADMINS = `/${routes.SUB_ADMINS}`;
export const ABS_SUB_ADMIN_ADD = `/${routes.SUB_ADMINS}/${routes.ADD}`;
export const ABS_SUB_ADMIN_EDIT = `/${routes.SUB_ADMINS}/${routes.EDIT}`;
export const ABS_SUB_ADMIN_DETAILS = `/${routes.SUB_ADMINS}/${routes.DETAILS}`;
