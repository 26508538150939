import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { CommonService } from './services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Kudu App';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(
    private _common:CommonService,
  ) { }

  ngOnInit() {
      this.chechStatus();    
  }

 
  /**
   * @ONLINE_OFFLINE Get the online/offline status from browser window
   */
  chechStatus() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      window.location.reload();
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
         this._common.unsubscribe(this.subscriptions);
    }
  }

}
