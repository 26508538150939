export const ACCOUNT = "account";
export const LOGIN = "login";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";
export const CHANGE_PASSWORD = "change-password";
export const VERIFY_OTP = "verify-otp";
export const ADD = "add";
export const BULK_UPDATE = "bulk-update";
export const EDIT = "edit";
export const DETAILS = "details";
export const QR_CODE = "qr-code";

export const PROFILE = "profile";
export const DASHBOARD = "dashboard";
export const VERSION = "version";

export const BANNERS = "banners";
export const COUPONS = "coupons";
export const PROMOS = "promos";
export const USER = "customer-management";
export const RESTRO = "restaurants";
export const BUTTOM_MENU = "bottom-menu";
export const PAYMENTS = "payment";
export const EVENT = "event";
export const STAMPS = "stamp-management";
export const DRIVER = "driver-management";
export const PARTNERSHIP = "partnership";
export const POPUP = "adding-popup";

export const GLOBAL_PAYMENTS = "global-payment";
export const ORDERS = "order";
export const MENU = "menu";
export const ITEM = "item";
export const CUSTOMIZATION = "customization";
export const PROMO_OFFER = "promo-&-offer";
export const LOYALITY = "loyality";
export const RECOMMENDATION = "recommendation";
export const LOCATION = "location";

export const GENERIC = "generic";
export const DINEIN_TUTORIAL = "dinein-tutorial";
export const ADDING_POPUP = "adding-popup";
export const SEGMENT = "segment";
export const CUSTOMER = "customer";
export const REPORT_ANALYTICS = "report-analyitics";
export const GENERAL_SETTING = "general-setting";
export const MAY_YOU_LIKE = "may-you-also-like";

export const NOTIFICATION = "notifications";
export const STANDARD_NOTIFICATION = "standard-notifications";
export const CMS = "cms";
export const TERM_CONDITIONS = "terms-&-conditions";
export const TERM_CONDITIONS_ARABIC = "terms-&-conditions-arabic";
export const PRIVACY_POLICY = "privacy-policy";
export const PRIVACY_POLICY_ARABIC = "privacy-policy-arabic";
export const ABOUT_US = "about-us";
export const ABOUT_US_ARABIC = "about-us-arabic";
export const OUR_VISION = "our-vision";
export const OUR_VISION_ARABIC = "our-vision-arabic";
export const FAQ = "faq";
export const HOME = "home";
export const ACTIVITY_LOG = "activity-logs";

export const DRIVER_PRIVACY_ARABIC = "driver-privacy-policy-arabic";
export const DRIVER_PRIVACY = "driver-privacy-policy";
export const DRIVER_TERM = "driver-terms-conditions";
export const DRIVER_TERM_ARABIC = "driver-terms-conditions-arabic";
export const FAQ_ARABIC = "faq-arabic";
export const SMILE = "smile";
export const SMILE_ARABIC = "smile-arabic";
export const SUPPORT = "support";
export const CANCELLATIONPOLICY = "cancellation-policy";
export const ROLES_ACCESS = "roles-and-access";
export const ROLES = "roles";
export const SUB_ADMINS = "sub-admins";
export const FEEDBACK = "feedback";
