import { AuthGuard } from './services/guards/auth-guard/auth-guard.guard';
import { LogInGuard } from './services/guards/log-in/log-in.guard';
import { NgModule } from '@angular/core';
import {
  ACCOUNT, TERM_CONDITIONS, PRIVACY_POLICY,
  ABOUT_US, FAQ, OUR_VISION, TERM_CONDITIONS_ARABIC, PRIVACY_POLICY_ARABIC, ABOUT_US_ARABIC, DRIVER_TERM, DRIVER_PRIVACY, DRIVER_PRIVACY_ARABIC, DRIVER_TERM_ARABIC
} from './constants/routes';
import { RouterModule, Routes } from '@angular/router';
import { OUR_VISION_ARABIC, FAQ_ARABIC } from './constants/routes';

const routes: Routes = [
  { path: '', redirectTo: ACCOUNT, pathMatch: 'full' },
  { path: ACCOUNT, loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule), canActivate: [LogInGuard] },
  { path: '', loadChildren: () => import('./modules/layouts/layouts.module').then(m => m.LayoutsModule), canActivate: [AuthGuard] },
  { path: TERM_CONDITIONS, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: TERM_CONDITIONS_ARABIC, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: PRIVACY_POLICY, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: PRIVACY_POLICY_ARABIC, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: ABOUT_US, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: ABOUT_US_ARABIC, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: OUR_VISION, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: OUR_VISION_ARABIC, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: FAQ, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: FAQ_ARABIC, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: DRIVER_TERM, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: DRIVER_TERM_ARABIC, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: DRIVER_PRIVACY_ARABIC, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: DRIVER_PRIVACY, loadChildren: () => import('./modules/cms-pages/cms-pages.module').then((m) => m.CmsPagesModule) },
  { path: '**', loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule) },
]

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
