export const environment = {
  production: false,
  API_BASE_PATH: "https://api-dev.alkhafeef.com.sa/",
  API_KEY: "123456",
  ADMIN_URL: "https://admin-dev.alkhafeef.com.sa/account/login",
  WEB_URL: "https://web-dev.alkhafeef.com.sa",
  CRED: "designer@alkhafeef.com.sa ,Admin@123",
  FINGER_PRINT: "Basic " + btoa(`${"Alkhafeef"}:${"Alkhafeef@123"}`),
  mapsApiKey: btoa(`${"AIzaSyAa5--GGnLEE0fvBHXNRRCByn_T0WvqrG0"}`),
};
