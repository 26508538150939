// const ADMIN_BASE: string = 'admin';
const ADMIN_BASE: string = "admins/api/v1";
const BANNER_BASE: string = "banner";
const NOTIFICATION_BASE: string = "notification";
const ROLE_BASE: string = "role";
const RESTRO_BASE: string = "restaurants";
const RESTAURANT_BASE: string = "restaurant";
const SUB_ADMIN_BASE: string = "subAdmin";
const MENU_BASE: string = "menu";
const ITEM_BASE: string = "item";
const STATIC_CONTENT_BASE: string = "content";
const FEEDBACK_BASE: string = "feedback";

/**
 * @ACCOUNT_RELATED_END_POINTS
 */
// export const LOGIN_API = `${ADMIN_BASE}/login`;
// export const LOGOUT_API = `${ADMIN_BASE}/logout`;
// export const FORGOT_PASSWORD_API = `${ADMIN_BASE}/forgot-password`;
// export const RESET_PASSWORD_API = `${ADMIN_BASE}/reset-password`;
// export const CHANGE_PASSWORD_API = `${ADMIN_BASE}/change-password`;
// export const VERIFY_OTP_API = `${ADMIN_BASE}/verify-otp`;
// export const USER_INFO_API = `${ADMIN_BASE}/profile`;

export const LOGIN_API = `${ADMIN_BASE}/login`;
export const LOGOUT_API = `${ADMIN_BASE}/logout`;
export const FORGOT_PASSWORD_API = `${ADMIN_BASE}/forgotPassword`;
export const RESET_PASSWORD_API = `${ADMIN_BASE}/resetPassword`;
export const CHANGE_PASSWORD_API = `${ADMIN_BASE}/changePassword`;
export const VERIFY_OTP_API = `${ADMIN_BASE}/verifyOtp`;
export const USER_INFO_API = `${ADMIN_BASE}/details`;
export const EDIT_PROFILE_API = `${ADMIN_BASE}/profile`;
export const ADMIN_MOBILE_VERIFY_API = `${ADMIN_BASE}/verifyMobileNumber`;
export const ADMIN_MOBILE_OTP_API = `${ADMIN_BASE}/verifyMobileOtp`;

/**
 * @BUTTOM_NAV_RELATED_END_POINTS
 */
export const BUTTOM_NAV_API = `${ADMIN_BASE}/content/bottom-navigation`;

/**
 * @HOME_NAV_RELATED_END_POINTS
 */
export const HOME_NAV_API = `${ADMIN_BASE}/content/homepage-sequence`;
export const CATEGORY_GET_API = `${ADMIN_BASE}/content/miscellaneousDetails`;
export const CATEGORY_EDIT_API = `${ADMIN_BASE}/content/editMiscellaneous`;
/**
 * @EVENT_RELATED_END_POINTS
 */
export const EVENT_API = `${ADMIN_BASE}/menuEventList`;
export const EVENT_ADD_API = `${ADMIN_BASE}/createMenuEvent`;
export const EVENT_EDIT_API = `${ADMIN_BASE}/editMenuEvent`;
export const EVENT_DETAILS_API = (id) => `${ADMIN_BASE}/menuEventDetails/${id}`;
export const EVENT_DELETE_API = (id) => `${ADMIN_BASE}/deleteMenuEvent/${id}`;
/**
 * @PARTNERSHIP_RELATED_END_POINTS
 */
export const PARTNERSHIP_API = `${ADMIN_BASE}/partnerList`;
export const PARTNERSHIP_ADD_API = `${ADMIN_BASE}/addPartner`;
export const PARTNERSHIP_EDIT_API = `${ADMIN_BASE}/editPartner`;
export const PARTNERSHIP_DETAILS_API = `${ADMIN_BASE}/partnerDetails`;
export const PARTNERSHIP_DELETE_API = (id) =>
  `${ADMIN_BASE}/deletePartner/${id}`;

/**
 * @ACTIVITY_API_RELATED_END_POINTS
 */
export const ACTIVITY_API = `${ADMIN_BASE}/activity-list`;
export const ACTIVITY_DETAILS_API = `${ADMIN_BASE}/activity-details`;

/**
 * @DINEIN_TUTORIAL_API_RELATED_END_POINTS
 */
export const DINEIN_TUTORIAL_ADD_API = `${ADMIN_BASE}/restaurant/dineintutorial`;
export const DINEIN_TUTORIAL_API = (id: string) => `${ADMIN_BASE}/dineIn/${id}`;

/**
 * @SEGMENT_RELATED_END_POINTS
 */

export const SEGMENT_LIST = `${ADMIN_BASE}/segments`;
export const SEGMENT_DETAILS = (id: string) => `${ADMIN_BASE}/segments/${id}`;
export const DELETE_SEGMENTS_API = (id: string) =>
  `${ADMIN_BASE}/segments/${id}`;
export const SEGMNET_EXPORT = (segmentId) =>
  `${ADMIN_BASE}/segments/export/${segmentId}`;

export const SEGMENT_STATUS_UPDATE_API = `${ADMIN_BASE}/segments`;
export const ADD_SEGMENTS_API = `${ADMIN_BASE}/segments`;
export const RESTRAUNT_LIST_API = `${ADMIN_BASE}/segments/restaurant`;
export const CUSTOMER_LISTING = `${ADMIN_BASE}/segments/user`;
export const EDIT_SEGMENT_API = `${ADMIN_BASE}/segments`;
export const ITEM_LISTING_API = `${ADMIN_BASE}/segments/itemList`;
/**
 * @DRIVER_RELATED_END_POINTS
 */
export const DRIVER_API = `${ADMIN_BASE}/drivers`;
export const DRIVER_REPORT_API = `${ADMIN_BASE}/drivers/report`;
export const DRIVER_UPDATE_API = `${ADMIN_BASE}/drivers/update`;
export const DRIVER_ORDER_API = `${ADMIN_BASE}/drivers/orders`;
export const DRIVER_REVIEW_API = `${ADMIN_BASE}/drivers/reviews`;
export const DRIVER_CASH_COLLECTED_API = `${ADMIN_BASE}/drivers/cash-collected`;

/**
 * @POP_MANAGEMENT_END_POINTS
 */
export const POPUP_LIST_API = `${ADMIN_BASE}/popUp`;
export const POPUP_ADD_API = `${ADMIN_BASE}/popUp`;
export const POPUP_DETAILS_API = (popUpId: string) =>
  `${ADMIN_BASE}/popUp/${popUpId}`;
export const POPUP_EDIT_API = `${ADMIN_BASE}/popUp`;
export const POPUP_DELETE_API = (popUpId: string) =>
  `${ADMIN_BASE}/popUp/${popUpId}`;
export const POPUP_STATUS_API = (popUpId: string) =>
  `${ADMIN_BASE}/popUp/${popUpId}`;
export const POPUP_COUPON_API = `${ADMIN_BASE}/popUp/couponList`;
/**
 * @STAMP_RELATED_END_POINTS
 */
export const STAMP_API = `${ADMIN_BASE}/stampList`;
export const STAMP_ADD_API = `${ADMIN_BASE}/createStamp`;
export const STAMP_EDIT_API = `${ADMIN_BASE}/editStamp`;
export const STAMP_DETAILS_API = (id) => `${ADMIN_BASE}/stampDetails/${id}`;
export const STAMP_DELETE_API = (id) => `${ADMIN_BASE}/deleteStamp/${id}`;

/**
 * @GLOBAL_PAYMENT_RELATED_END_POINTS
 */
export const GLOBAL_PAYMENT_API = `${ADMIN_BASE}/payment/payment-gateway`;
export const PAYMENT_AMOUNT_API = `${ADMIN_BASE}/payment/total`;
export const GLOBAL_PAYMENT_DETAILS_API = `${ADMIN_BASE}/payment/global-payment`;
/**
 * @DASHBOARD_RELATED_END_POINTS
 */
export const DASHBOARD_API = `${ADMIN_BASE}/dashboard`;
export const DASHBOARD_COUNT_API = `${DASHBOARD_API}/get-count`;
export const DASHBOARD_GRAPH_API = `${DASHBOARD_API}/graph`;
export const DASHBOARD_ITEMS_API = `${DASHBOARD_API}/items`;

/**
 * @ORDERS_RELATED_END_POINTS
 */
export const ORDER_API = `${ADMIN_BASE}/orders`;
export const ORDER_STATUS_CHANGE_API = (id) =>
  `${ADMIN_BASE}/orderStatus/update/${id}`;
export const ORDER_FALIED_API = `${ADMIN_BASE}/failed-orders`;
export const ORDER_DETAILS_API = (id) => `${ADMIN_BASE}/orders/${id}`;
export const ORDER_FALIED_DETAILS_API = (id) =>
  `${ADMIN_BASE}/failed-orders/${id}`;

/**
 * @RECOMMENDATION_RELATED_END_POINTS
 */
export const RECOMMENDATION_API = `${ADMIN_BASE}/recommendation`;
export const RECOMMENDATION_DETAIL_API = `${ADMIN_BASE}/recommendationDetail`;

/**
 * @YOU_MAY_ALSO_LIKE_RELATED_END_POINTS
 */
export const YOU_MAY_ALSO_LIKE_ADD_API = `${ADMIN_BASE}/addOrEditYouMayAlsoLike`;
export const YOU_MAY_ALSO_LIKE_API = `${ADMIN_BASE}/youMayAlsoLikeList`;
export const YOU_MAY_ALSO_LIKE_DETAILS_API = `${ADMIN_BASE}/youMayAlsoLikeDetails`;
export const YOU_MAY_ALSO_LIKE_DELETE_API = `${ADMIN_BASE}/deleteYouMayAlsoLike`;

/**
 * @SETTINGS_RELATED_END_POINTS
 */
export const ADD_SETTINGS_API = `${ADMIN_BASE}/addGeneralSettings`;
export const EDIT_SETTINGS_API = `${ADMIN_BASE}/editGeneralSettings`;
export const GET_SETTINGS_API = `${ADMIN_BASE}/getGeneralSettings`;

/**
 * @SUB_ADMIN_RELATED_END_POINTS
 */
export const SUB_ADMIN_LIST_API = `${ADMIN_BASE}/${SUB_ADMIN_BASE}`;
export const EDIT_SUB_ADMIN_API = (id) =>
  `${ADMIN_BASE}/${SUB_ADMIN_BASE}/${id}`;

/**
 * @USER_RELATED_END_POINTS
 */
export const USER_API = `${ADMIN_BASE}/user`;
export const EDIT_USER_API = `${ADMIN_BASE}/user/edit`;
export const BLOCK_UNBLOCK_USER_API = `${ADMIN_BASE}/user/status`;
/**
 * @LOYALITY_RELATED_END_POINTS
 */
export const LOYALITY_API = `${ADMIN_BASE}/loyaltyProgram`;
export const BLOCK_UNBLOCK_LOYALITY_API = `${ADMIN_BASE}/loyality/status`;

/**
 * @GENERIC_MANAGEMENT_RELATED_END_POINTS
 */
export const GENERIC_MANAGEMENT_API = `${ADMIN_BASE}/generic/list`;
export const GENERIC_MANAGEMENT_STATUS = (id) =>
  `${ADMIN_BASE}/generic/update-status/${id}`;
export const GENERIC_MANAGEMENT_UPDATE = (id) =>
  `${ADMIN_BASE}/generic/update/${id}`;
export const ADD_RESTRO = `${ADMIN_BASE}/restaurant/add`;

/**
 * @VERSION_MANAGEMENT_RELATED_END_POINTS
 */
export const VERSION_MANAGEMENT_API = `${ADMIN_BASE}/version`;
export const VERSION_MANAGEMENT_DETAILS_API = (id) =>
  `${ADMIN_BASE}/version/${id}`;
export const VERSION_MANAGEMENT_STATUS_API = (id) =>
  `${ADMIN_BASE}/version/update-status/${id}`;
export const ADD_VERSION_MANAGEMENT_API = `${ADMIN_BASE}/version`;

/**
 * @REPORT_ANALYTICS_RELATED_END_POINTS
 */
export const REPORT_ANALYTICS_API = `${ADMIN_BASE}/reports/payments`;
export const REPORT_USER_API = `${ADMIN_BASE}/reports/users`;
export const REPORT_COUNT_API = `${ADMIN_BASE}/reports/get-counts`;

/**
 * @PROMO_OFFER_RELATED_END_POINTS
 */
export const ADD_PROMO_OFFER_API = `${ADMIN_BASE}/addPromotions`;
export const INSTORE_PROMO_OFFER_API = `${ADMIN_BASE}/instorePromo`;
export const EDIT_PROMO_OFFER_API = `${ADMIN_BASE}/editPromoDetails`;
export const MENU_AND_ITEM_API = `${ADMIN_BASE}/menuAndItemList`;
export const PROMO_OFFER_API = `${ADMIN_BASE}/promoList`;
export const PROMO_OFFER_DETAILS_API = (id) =>
  `${ADMIN_BASE}/promoDetails/${id}`;
export const PROMO_OFFER_ACTION_API = `${ADMIN_BASE}/activeInactivePromos`;
export const PROMO_ITEM_LIST_API = `${ADMIN_BASE}/itemList`;
export const PROMO_OFFE_API = `${ADMIN_BASE}/promoandoffer`;
export const INSTORE_PROMO_API = `${ADMIN_BASE}/instorepromo`;
export const BLOCK_UNBLOCK_PROMO_OFFER_API = `${ADMIN_BASE}/loyality/status`;
export const GENERAL_PROMO_API = `${ADMIN_BASE}/generalpromo`;

/**
 * @COUPONS_RELATED_END_POINTS
 */
export const ADD_COUPONS_API = `${ADMIN_BASE}/addCoupons`;
export const EDIT_COUPONS_API = `${ADMIN_BASE}/editCoupons`;
export const COUPONS_API = `${ADMIN_BASE}/couponList`;
export const COUPONS_CODE_API = (id) => `${ADMIN_BASE}/couponCodeList/${id}`;
export const COUPONS_DETAILS_API = (id) => `${ADMIN_BASE}/couponDetails/${id}`;
export const COUPONS_ACTION_API = `${ADMIN_BASE}/activeInactiveCoupon`;

/**
 * @PAYMENT_RELATED_END_POINTS
 */
export const PAYMENT_API = `${ADMIN_BASE}/payment/list`;
export const PAYMENT_DETAILS_API = (id) =>
  `${ADMIN_BASE}/payment/details/${id}`;

/**
 * @BANNERS_RELATED_END_POINTS
 */
export const BANNER_API = `${ADMIN_BASE}/${BANNER_BASE}`;
export const BANNER_BLOCK_UNBLOCK_API = `${BANNER_BASE}/block-unblock`;
export const BANNER_EXPORT_API = `${BANNER_BASE}/export`;

/**
 * @MENU_RELATED_END_POINTS
 */
export const MENU_API = `${ADMIN_BASE}/${MENU_BASE}`;
export const DELETE_MENU_API = `${ADMIN_BASE}/item`;
export const MENU_HISTORY_API = `${ADMIN_BASE}/menuHistory`;
export const MENU_PROMO_API = `${ADMIN_BASE}/menu-list`;
export const MENU_ITEMLIST_API = `${ADMIN_BASE}/menuitems`;
export const SET_TIME_API = `${ADMIN_BASE}/${MENU_BASE}/timerange`;
export const EXTRA_ITEM_API = `${ADMIN_BASE}/${ITEM_BASE}/modifier`;
export const MENU_IMAGE_API = `${ADMIN_BASE}/menuimage`;
export const ALLERGIC_COMPONENT_API = `${ADMIN_BASE}/allergiccomponents`;
export const MOD_ITEM_API = (modgroupId) =>
  `${ADMIN_BASE}/modifiers/${modgroupId}`;
export const EDIT_MODIFIER_API = `${ADMIN_BASE}/modifiers`;
export const CUSTOMISATION_AVALIBALE_API = `${ADMIN_BASE}/modifier/availability`;
export const MENU_TEMPLATE_API = `${ADMIN_BASE}/menutemplate`;
export const MENU_SYNC_API = `${ADMIN_BASE}/${MENU_BASE}/syncAllMenuItems`;
export const MENU_PUBLISHED_API = `${ADMIN_BASE}/${MENU_BASE}/publish`;
export const MENU__AVAILABILITY_API = `${ADMIN_BASE}/${MENU_BASE}/availability`;
export const MENU_BLOCK_UNBLOCK_API = `${ADMIN_BASE}/${MENU_BASE}/block-unblock`;
export const MENU_SYNCLOG_API = `${ADMIN_BASE}/menuItem/syncLog`;
export const ITEM_API = `${ADMIN_BASE}/${ITEM_BASE}`;
export const ITEM_SYNC_API = `${ADMIN_BASE}/${ITEM_BASE}/sync`;
export const MODIFIERS_SYNC_API = `${ADMIN_BASE}/modifiers/sync`;
export const ITEM_AVAILABILITY_API = `${ADMIN_BASE}/${ITEM_BASE}/availability`;

/**
 * @MEDIA_RELATED_END_POINT
 */
export const MEDIA_API = `${ADMIN_BASE}/media`;

/**
 * @LOCATION_ZONE_RELATED_END_POINTS
 */
export const LOCATION_ZONE_API = `${ADMIN_BASE}/zone/restaurants`;
export const LOCATION_ZONE_DETAILS_API = (id) =>
  `${ADMIN_BASE}/zone/restaurant-details/${id}`;
export const LOCATION_ZONE_EDIT_API = (id) => `${ADMIN_BASE}/zone/${id}`;
export const LOCATION_ZONE_DELETE_API = (id) => `${ADMIN_BASE}/zone/${id}`;
export const LOCATION_ZONE_ADD_API = `${ADMIN_BASE}/zone`;

/**
 * @RESTROS_RELATED_END_POINTS
 */
export const RESTRO_API = `${ADMIN_BASE}/${RESTRO_BASE}`;
export const LOCATION_API = `${ADMIN_BASE}/restaurants/location`;
export const RESTRO_DETAILS_API = `${ADMIN_BASE}/${RESTAURANT_BASE}`;
export const RESTRO_BULK_UPDATE_API = `${ADMIN_BASE}/${RESTAURANT_BASE}/bulk_update`;
export const RESTURANT_PUBLISHED_API = `${ADMIN_BASE}/${RESTAURANT_BASE}/publish`;
export const ORDER_ACCEPT_API = `${ADMIN_BASE}/${RESTAURANT_BASE}/availability`;
export const RESTRO_BLOCK_UNBLOCK_API = `${ADMIN_BASE}/${RESTAURANT_BASE}/status`;
export const RESTRO_SYNC_API = `${ADMIN_BASE}/${RESTAURANT_BASE}/sync`;
export const RESTRO_EXPORT_API = `${ADMIN_BASE}/${RESTRO_BASE}/export`;

/**
 * @BROADCAST_NOTIFICATION_RELATED_END_POINTS
 */
export const NOTIFICATION_API = `${ADMIN_BASE}/${NOTIFICATION_BASE}`;
export const STANDARD_NOTIFICATION_API = `${ADMIN_BASE}/standardNotification`;
export const STANDARD_NOTIFICATION_STATUS_API = `${ADMIN_BASE}/standardNotification/status`;
export const NOTIFICATION_ACTION_API = `send-notification`;
export const NOTIFICATION_EDIT_API = (notificationId) =>
  `${ADMIN_BASE}/notification/${notificationId}`;
export const RESEND_NOTIFICATION_API = `${ADMIN_BASE}/notification/resend`;

/**
 * @BROADCAST_NOTIFICATION_RELATED_END_POINTS
 */
export const FEEDBACK_API = `${ADMIN_BASE}/${FEEDBACK_BASE}`;
export const FEEDBACK_ACTION_API = ``;

/**
 * @ROLES_ACCESS_RELATED_END_POINTS
 */
export const ROLES_API = `${ADMIN_BASE}/${SUB_ADMIN_BASE}/permissions`;
export const ROLE_BLOCK_UNBLOCK_API = `${ADMIN_BASE}/${ROLE_BASE}/block-unblock`;
export const SUB_ADMINS_API = `${ADMIN_BASE}/${SUB_ADMIN_BASE}`;
// export const SUB_ADMIN_BLOCK_UNBLOCK_API = `${SUB_ADMIN_BASE}/block-unblock`;
export const SUB_ADMIN_BLOCK_UNBLOCK_API = (id) =>
  `${ADMIN_BASE}/${SUB_ADMIN_BASE}/block/${id}`;

/**
 * @CMS_RELATED_END_POINTS
 */
export const CMS_API = (type) => `${ADMIN_BASE}/${STATIC_CONTENT_BASE}/${type}`;
export const CMS = `${ADMIN_BASE}/${STATIC_CONTENT_BASE}/details`;
export const UPDATE_CMS_API = `${ADMIN_BASE}/${STATIC_CONTENT_BASE}`;
export const UPDATE_FAQ_SEQUENCE_API = `${ADMIN_BASE}/content/faqsequence`;
// export const FAQ_API = `${STATIC_CONTENT_BASE}/faq`;
export const FAQ_API = `${ADMIN_BASE}/${STATIC_CONTENT_BASE}/faq`;
export const EDIT_FAQ_API = (id) =>
  `${ADMIN_BASE}/${STATIC_CONTENT_BASE}/faq/${id}`;
export const SUPPORT_API = `${ADMIN_BASE}/${STATIC_CONTENT_BASE}/support`;
