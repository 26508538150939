export const OFFLINE = "Connection lost! You are not connected to Internet";
export const ONLINE = "Back to online";
export const NO_INTERNET = "No Internet Connection";
export const TIME_OUT = "Connection timed out. Please retry";
export const INTERNAL_SERVER_ERROR =
  "Couldn't connect to the server. Please try again";
export const INVALID_ID_ERROR = "Invalid ID";
export const VERIFICATION_CODE = "Please enter verification code";
export const SOMETHING_WRONG = "Something went wrong, Please try again later";
export const NO_ACTION = "You can't perform this action";
export const SYNCING_MESSAGE = {
  INPROGESS:
    "Menu Sync is in-progress, you can not perform any action in menu management.",
  COMPLETE: "Menu Sync is completed, you can perform all action",
};
export const SYNCING_RESTRO_MESSAGE = {
  INPROGESS:
    "Restaurant Sync is in-progress, you can not perform any action in Restaurant.",
  COMPLETE: "Restaurants Sync is completed, you can perform all action",
};
export const MAX_VIDEO_SIZE = 10000000;
export const VIDEO_FORMAT = "video/mp4,video/gif";
// export const VIDEO_ERROR = `Only ${IMAGE_FORMAT} images and mp4, webm, mov format are allowed`

export const UNDER_DEV = "Under Development !!";

export const RUSH_HOURS_MES =
  "Rush hours is for managing the delivery services for a temporary time period. Are you sure you want to enable the rush hours?";
export const DELIVERY_CHECK_MES =
  "This will enable your delivery services and disable the rush hours. Please confirm to proceed";

export const ACCOUNT_ERROR_MESSAGES = {
  OPENING_REQ: "Please provide Opening Time",
  COLOR_CODE: "Please select Stamp Color",
  UPLOAD_IMAGE: "Please upload Image",
  UPLOAD_IMAGE_VIDEO_GIF:"Please upload Video/Gif",
  UPLOAD_AR_IMAGE: "Please upload arabic Image",

  UPLOAD_ITEM_IMAGE: "Please upload the Item Image",
  UPLOAD_ITEM_VIDEO_GIF: "Please upload the Item Video/GIF",

  FROM_TIME_REQ: "Please provide Valid From Time",
  TO_TIME_REQ: "Please provide Valid To Time",
  CLOSING_REQ: "Please provide Closing Time",
  SELECT_BANNER_CATEGORY: "Please select Category",
  SELECT_MENU: "Please select Menu",
  SELECT_DAY_REQ: "Please Select Day",
  NAME_REQ: "Please enter Full Name",
  FIRST_NAME_REQ: "Please enter First name",
  LAST_NAME_REQ: "Please enter last name",
  MIN_NAME_REQ: "Please enter atleast 3 characters",
  MOBILE_REQ: "Please enter Phone Mumber",
  INVALID_MOBILE: "Please enter a valid Phone Number",
  MIN_MOBILE: "Please enter atleast 10 digit Phone Number",
  PASSWORD_REQ: "Please enter the Password",
  INVALID_PASSWORD: "Please enter a valid Password",
  DELIVERY_TYPE: "Please select the  Delivery Type",
  REDIRECTION_TYPE: "Please select the Type of Redirection",
  SELECT_CATEGORY: "Please select at-least one Category",
  SELECT_ITEM: "Please select at-least one Item",
  SELECT_ORDER_TYPE: "Please select at-least one Delivery Type",
  SELECT_NAVIGATION: "Please select Navigation",
  NO_SPACE_PASSWORD: "Password can't start or end with a blank space",
  EMAIL_REQ: "Please enter the  Email ID",
  INVALID_EMAIL: "Please enter a valid Email ID",
  ONLY_ALPHABET: "Only alphabets are allowed",
};

export const PASSWORD_ERROR_MESSAGES = {
  OLD_PASSWORD_REQ: "Please enter Old Password",
  PASSWORD_REQURIMENT:
    "Password must be between 8-15 characters and have at-least 1 upper case, 1 lower case, 1 special character [@,$,%,&] & 1 numeric value",
  NO_SPACE_OLD_PASSWORD: "Old password can't start or end with a blank space",
  NEW_PASSWORD_REQ: "Please enter New Password",
  INVALID_NEW_PASSWORD:
    "Password must be between 8-15 characters and have at-least 1 upper case, 1 lower case, 1 special character [@,$,%,&] & 1 numeric value",
  // INVALID_NEW_PASSWORD: "Please enter a valid New Password",
  // INVALID_OLD_PASSWORD: "Please enter a valid Old Password",
  INVALID_OLD_PASSWORD:
    "Old Password must be between 8-15 characters and have at-least 1 upper case, 1 lower case, 1 special character [@,$,%,&] & 1 numeric value",
  // INVALID_C_PASSWORD: "Please enter a valid Confirm Password",
  NO_SPACE_NEW_PASSWORD: "New password can't start or end with a blank space",
  C_PASSWORD_REQ: "Please enter Confirm  Password",
  INVALID_C_PASSWORD:
    "Confirm New Password must be between 8-15 characters and have at-least 1 upper case, 1 lower case, 1 special character [@,$,%,&] & 1 numeric value",
  NO_SPACE_C_PASSWORD:
    "Confirm New Password can't start or end with a blank space",
  CONFORM_PASSWORD_MATCH_ERR:
    "New Password and Confirm Password should be same.",
  OLD_NEW_PASSWORD_MATCH_ERR:
    "You have used this Password before. Please enter valid New Password",
  WRONG_OLD_PASSWORD: "Entered old password was incorrect",
};

export const LISTING_COMMON_MESSAGES = {
  ACTIVE_TITLE: "Active ",
  ACTIVATE_TITLE: "On Duty ",
  BLOCK_TITLE: "Inactive ",
  INACTIVATE_TITLE: "Off Duty ",
  DELETE_TITLE: "Delete ",
  CLOSE_TITLE: "Close Order",
  CANCEL_TITLE: "Cancel Order",

  ACTIVE_MSG: "Are you sure you want to active",
  ACTIVATE_MSG: "Are you sure you want to on duty",
  CLOSE_ORDER: "Are you sure you want to close order ?",
  CANCEL_ORDER: "Are you sure you want to cancel order ?",

  BLOCK_MSG: "Are you sure you want to off duty",
  INACTIVATE_MSG: "Are you sure you want to inactive",
  DELETE_MSG: "Are you sure you want to delete",
};

export const NOTIFICATION_MESSAGES = {
  SEND_NOTIFICATION_TITLE: "Send Notification",
  EDIT_NOTIFICATION_TITLE: "Edit Notification",
  RESEND_NOTIFICATION_TITLE: "Resend Notification",

  SEND_NOTIFICATION_MSG: "Are you sure you want to send this notification",
  EDIT_NOTIFICATION_MSG: "Are you sure you want to edit this notification",
  RESEND_NOTIFICATION_MSG: "Are you sure you want to resend this notification",
};
export const BANNER_ERROR_MESSAGES = {
  TITLE_REQ: "Please enter banner title",
  HEADING_REQ: "Please enter banner heading",
  IMG_REQ: "Please select atleast one banner image",
  CONTENT_REQ: "Please fill banner content",
};

export const LOCATION_ZONE = {
  VALID_RESTRO: "Please search valid Restaurant Location",
  VALID_RESTRO_NAME: "Please search valid Restaurant Name",
  NO_SELECTED_ZONE:
    "There is no selected zone Type.You should choose Circle or Polygon Zone",
  RESTURANT_EDIT: "You should edit zone with respective resturant",
  EXIST_RESTRO: `You cannot create zone for the selected area as there is some other restaurant 
  serving in some part of your created zone.`,
};

export const SUB_ADMIN_ERROR_MESSAGES = {
  NAME_REQ: "Please enter name",
  EMAIL_REQ: "Please enter email address",
  INVALID_EMAIL: "Please enter a valid email address",
  ROLE_REQ: "Please select sub admin role",
};

export const SELECT_OFFER = {
  DELIVERY: "Please select the  Delivery Type",
};

export const ALLERGIC_COMP = {
  ALLERGIC_IMAGE: "Please upload Image for Allergic Component",
  ALLERGIC_SELECTED: "This Allergic Component has already selected",
};
export const ITEM_COMPONENT = {
  LOCATION: "Location Already Selected",
};

export const RESTURANT_ERROR = {
  RESTRO_IMAGE: "Please upload Restaurant Image.",
  RESTRO_PAYMENT_MAND:
    "At least one payment method is mandatory ( Except Apple Pay)",
  RESTRO_DELIVER_PAYMENT_MAND: "Please select Payment mode for Delivery",
  RESTRO_DINEIN_PAYMENT_MAND: "Please select Payment mode for Dine-In",

  RESTRO_PICKUP_PAYMENT_MAND: "Please select Payment mode for Pickup",
  RESTRO_CARHOP_PAYMENT_MAND: "Please select Payment mode for Carhop",
  OPEN_TIME: "Please choose Opening Time.",
  CLOSE_TIME: "Please choose Closing Time.",
  PICKUP_FROM_TIME: "Please choose Pickup From Time.",
  PICKUP_TO_TIME: "Please choose Pickup To Time.",
  DINEIN_FROM_TIME: "Please choose Dine-In From Time.",
  DINEIN_TIME: "Dine-In Time should be in closing and opening time",
  DINEIN_TO_TIME: "Please choose Dine-In To Time.",
  DELIVERY_FROM_TIME: "Please choose Delivery From Time.",
  DELIVERY_TO_TIME: "Please choose Delivery To Time.",
  CURBSIDE_FROM_TIME: "Please choose Carhop From Time.",
  CURBSIDE_TO_TIME: "Please choose Carhop To Time.",
  DELIVERY_TYPE_ERROR:
    "Please select Carhop/Pickup/Delivery/Dine-In Payment Gateway.",
};

export const YOU_MAY_LIKE = {
  ITEM_ID_ALLOWED: "Duplicate Item ID is not allowed",
  SERVICE_TYPE: "Please choose any Service Type",
};


export const VISIBILITY_PROMO = [
  { viewValue: "Over All", value: "1" },
  { viewValue: "Per Day", value: "2" },
  // { viewValue: "Custom Per Day", value: "3" },
  { viewValue: "Per week", value: "4" },
  // { viewValue: "Custom Per Week", value: "5" },
  // { viewValue: "Custom", value: "6" },
];

export const NOTIFICATION_ERROR_MESSAGES = {
  TITLE_REQ: "Please enter title",
  USER_REQ: "Please enter  any User",
  USER_ALREDY: "User Already Selected",
  LOCATION_ALREDY: "Location Already Selected",
  MESSAGE_REQ: "Please enter message",
  COUPON_REQ: "Please enter Coupon Code",
  COUNTRY_REQ: "Please enter Country code",
  PLATFORM_REQ: "Please select platform",
  DATE_REQ: "Please select date",
  TIME_REQ: "Please select time",
};

export const MAX_IMG_SELECTION = 4;
export const FILE_EXIST = "Selected image is already exist";
export const MAX_IMG_SELECTION_ERR = (count = MAX_IMG_SELECTION) =>
  `You can select upto ${count} images only`;
export const FUTURE_TIME = "Please select future time";
export const  WHOLE_NUMBER="Please enter amount as a whole number or in multiples of .5";
export const MESSAGE_TYPE = {
  ORDERS_AND_PURCHASE: "1",
  PROMOS_AND_OFFERS: "2",
  OTHERS: "3",
};

export const MESSAGE_TYPE_DETAILS = {
  ["1"]: "Orders and Purchase",
  ["2"]: "Promos and Offers",
  ["3"]: "Others",
};

export const FAQ_ERROR_MESSAGES = {
  QUESTION_REQ: "Please enter Question",
  QUESTION_ARABIC_REQ: "Please enter Question in Arabic",
  ORDER_REQ: "Please enter question display order",
  INVALID_ORDER: "Question display order should be greater than Zero",
  ANSWER_REQ: "Please enter Answer",
  ANSWER_Arabic_REQ: "Please enter Answer in Arabic",
  TYPE_REQ: "Please select Type ",
};

export const S3_BUCKET_ERROR = "Network Failure or Sync up your system clock";
export const BULK_EROR =
  "Please filter the athletes first on behalf of workout before performing the bulk operation";
export const ACTION_ERROR = (type) => `Please wait for ${type} action`;
export const TITLE_CONFIRMATION = (titleText) => `${titleText} Confirmation`;
export const MSSG_CONFIRMATION = (MssgText) =>
  `Are you sure you want to ${MssgText}?`;

export const EDITOR_COMMON_MESSAGES = {
  CONTENT_REQ: "Please fill some information about page",
  CMS_ACTION: (action: string) => `Content ${action} successfully`,
};

export const MONTH_NAME = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const DAY_NAME = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const DATE_TYPES = {
  SHORT_TIME: "h:mm a",
  MEDIUM_DATE: "MMM d, yyyy",
  FULL_DATE: "EE, MMM d, yyyy",
  VALIDITY_TIME: "MMM d, y - hh:mm:ss a",
  DATE_WITH_TIME: "MMM d, y - hh:mm a",
  DATE_TIME_WITH_COMMA_SEP: "MMM d, y, hh:mm a",
};

export const FREQUENCY_LIST_FOR_COUPON = {
  ["1"]: "Over All",
  ["2"]: "Per Day",
  ["4"]: "Per Week",
};

export const LIST_TYPE = {
  LISTING: "LISTING",
  CSV: "CSV",
};
export const ACCESS_LEVEL = {
  VIEW: 1,
  EDIT: 2,
};
export const FAQ_TYPE = [{ name: "Other", value: "1" }];

export const PAYMENT_METHOD = {
  ["1"]: "Debit/Credit Card",
  ["2"]: "COD (Cash On Delivery)",
  ["3"]: "Apple Pay",
  ["4"]: "Card Machine",
};
export const GLOBAL_PAYMNET_METHOD = {
  ["isDebitCredit"]: "Debit/Credit Card",
  ["isCod"]: "Cash",
  ["isApplePay"]: "Apple Pay",
  ["isCardOnDelivery"]: "Card On Delivery",
  ["isCardOnPickup"]: "Card On Pickup",
  ["isCardOnCurbside"]: "Card On Carhop",
  ["isCardOnDinein"]: "Card On Dine-In",
};

export const FREQUENCY_LIST = {
  ["1"]: "One Time",
  ["2"]: "Every Time",
  ["3"]: "Custom",
  ["4"]: "One Time Per Day",
  ["5"]: "Custom Per Day",
};
export const PAYMENT_METHOD_ARRAY = [
  { value: "1", name: "Online Payment" },
  { value: "2", name: "Cash" },
  { value: "3", name: "Apple Pay" },
];

export const PAYMENT_DELIVERY_ARRAY = [
  { value: "isApplePay", name: "Apple Pay" },
  { value: "isCodDelivery", name: "Cash On Delivery" },
  { value: "isDebitCredit", name: "Online Payment" },
  { value: "isCardOnDelivery", name: "Card On Delivery" },
];
export const GLOBAL_PAYMENT_ARRAY = [
  { value: "isApplePay", name: "Apple Pay" },
  { value: "isCodDelivery", name: "Cash On Delivery" },
  { value: "isCodCurbside", name: "Cash On Carhop" },
  { value: "isCodPickup", name: "Cash On Pickup" },
  { value: "isCodDinein", name: "Cash On Dine-In" },
  { value: "isDebitCredit", name: "Online Payment" },
  { value: "isCardOnDelivery", name: "Card On Delivery" },
  { value: "isCardOnPickup", name: "Card On Pickup" },
  { value: "isCardOnCurbside", name: "Card On Carhop" },
  { value: "isCardOnDinein", name: "Card On Dine-In" },
];

export const PAYMENT_PICKUP_ARRAY = [
  { value: "isApplePay", name: "Apple Pay" },
  { value: "isCodPickup", name: "Cash On Pickup" },
  { value: "isDebitCredit", name: "Online Payment" },
  { value: "isCardOnPickup", name: "Card On Pickup" },
];
export const PAYMENT_CURBSIDE_ARRAY = [
  { value: "isApplePay", name: "Apple Pay" },
  { value: "isCodCurbside", name: "Cash On Carhop" },

  { value: "isDebitCredit", name: "Online Payment" },
  { value: "isCardOnCurbside", name: "Card On Carhop" },
];

export const PAYMENT_DINEIN_ARRAY = [
  { value: "isApplePay", name: "Apple Pay" },
  { value: "isCodDinein", name: "Cash On Dine-In" },
  { value: "isDebitCredit", name: "Online Payment" },
  { value: "isCardOnDinein", name: "Card On Dine-In" },
];

export const WORKING_DAY = [
  { name: "Monday" },
  { name: "Tuesday" },
  { name: "Wednesday" },
  { name: "Thursday" },
  { name: "Friday" },
  { name: "Saturday" },
  { name: "Sunday" },
];

export const USER_STATUS = [
  { name: "Active", value: 2 },
  { name: "Inactive", value: 1 },
];
export const DRIVER_STATUS = [
  { name: "On Duty", value: "on" },
  { name: "Off Duty", value: "off" },
];

export const STATUS = [
  { name: "Active", value: "active" },
  { name: "Inactive", value: "inactive" },
];

export const ORDER_STATUS = [
  // { name: "Order Pending", value: 1 },
  { name: "Order Placed", value: 2 },
  { name: "In Kitchen", value: 3 },
  { name: "Ready for Delivery", value: 4 },
  { name: "Out for Delivery", value: 5 },
  { name: "Delivered", value: 6 },
  { name: "Cancelled", value: 7 },
  // { name: "Refunded", value: 8 },
];

export const ORDER_STATUS_INLIST = {
  ["1"]: "Order Pending",
  ["2"]: "Order Placed",
  ["3"]: "In Kitchen",
  ["4"]: "Ready for Delivery",
  ["5"]: "Out for Delivery",
  ["6"]: "Delivered",
  ["7"]: "Cancelled",
  ["8"]: "Refunded",
};

export const ORDER_FOR_STATUS = [
  { name: "Acknowledged", value: true },
  { name: "Unacknowledged", value: false },
];

export const BANNER_TEMPLATE_TYPE = [
  { name: "Before Login", value: "BEFORE_LOGIN" },
  { name: "After Login", value: "AFTER_LOGIN" },
  { name: "Both", value: "BOTH" },
];

export const PLATFORM_ARRAY = [
  // { name: "Android", value: 1 },
  // { name: "iOS", value: 2 },
  { name: "Web", value: 3 },
];

export const NOTIFICATION_PLATFORM = [
  // { name: 'All', value: 4 },
  // { name: 'Android', value: 1 },
  // { name: 'iOS', value: 2 },
  // { name: 'Web', value: 3 }
  { name: "User", value: "user" },
];

export const CONTENT_TYPES = {
  OUR_VISION: 1,
  TERMS_AND_CONDITIONS: 3,
  DRIVER_TERMS_AND_CONDITIONS: 9,
  PRIVACY_POLICY: 2,
  DRIVER_PRIVACY_POLICY: 8,
  ABOUT_US: 4,
  CANCELATION_POLICY: 7,
  FAQ: "FAQ",
};

export const SEGMENT_DETAILS_FEILD1 = {
  [1]: "Item Purchased",
  [2]: "Order Location",
  [3]: "Order Payment Type",
  [4]: "Order Source",
  [5]: "Order Type",
  [6]: "Amount Spent",
  [7]: "Total Amount Spent",
  [8]: "Average Amount Spent",
  [9]: "Number Of Orders",
  [10]: "Order Date",
  [11]: "Last Order",
  [12]: "Payment Status",
};
export const SEGMNET_DETAILS_FEILD2 = {
  [1]: "Purchased",
  [2]: "Did not Purchased",
  [3]: "Is in",
  [4]: "Is not in",
  [5]: "Is",
  [6]: "Is not",
  [7]: "From",
  [8]: "Not from",
  [9]: "More Than",
  [10]: "Less Than",
  [11]: "Equals",
  [12]: "Since",
  [13]: "Between",
  [14]: "On",
};
export const SEGMENT_DETAILS_PAYMENT_TYPE = {
  [1]: "Debit/Credit Card",
  [2]: "Cash",
  [3]: "Card on Delivery",
  [4]: "Apple Pay",
};
export const SEGMENT_DETAILS_SOURCE_TYPE = {
  [1]: "Android",
  [2]: "IOS",
  [3]: "Web",
};
export const SEGMENT_DETAILS_SINCE = {
  [1]: "Year",
  [2]: "Month",
  [3]: "Weak",
  [4]: "Day",
};
export const SEGMENT_FIELD1 = [
  { viewValue: "Item Purchased", value: "1",disabled:false },
  { viewValue: "Order Location", value: "2",disabled:false },
  { viewValue: "Order Payment Type", value: "3" ,disabled:false},
  { viewValue: "Order Source", value: "4",disabled:false },
  { viewValue: "Order Type", value: "5",disabled:false },
  { viewValue: "Amount Spent", value: "6",disabled:false },
  { viewValue: "Total Amount Spent", value: "7" ,disabled:false},
  { viewValue: "Average Amount Spent", value: "8",disabled:false },
  { viewValue: "Number Of Orders", value: "9",disabled:false },
  { viewValue: "Order Date", value: "10",disabled:false },
  { viewValue: "Last Order", value: "11" ,disabled:false},
  { viewValue: "Payment Status", value: "12" ,disabled:false},
];
export const CUSTOM_MATCH = [
  { viewValue: "All", value: "all" },
  { viewValue: "Any", value: "any" },
];
export const SEGMENT_FIELD_ITEM_PURCHASED = [
  { viewValue: "Purchased", value: "1" },
  { viewValue: "Did not Purchased", value: "2" },
];
export const SEGMENT_FIELD_ORDER_LOCATION = [
  { viewValue: "Is in", value: "3" },
  { viewValue: "Is not in", value: "4" },
];
export const SEGMENT_FIELD_ORDER_PAYMENT_TYPE = [
  { viewValue: "Is", value: "5" },
  { viewValue: "Is not", value: "6" },
];
export const SEGMENT_FIELD_ORDER_SOURCE = [
  { viewValue: "From", value: "7" },
  { viewValue: "Not from", value: "8" },
];

export const SEGMENT_FIELD_AMOUNT_SPENT = [
  { viewValue: "More Than", value: "9" },
  { viewValue: "Less Than", value: "10" },
  { viewValue: "Equals", value: "11" },
];
export const SEGMENT_FIELD_ORDER_DATE = [
  { viewValue: "Since", value: "12" },
  { viewValue: "Between", value: "13" },
  { viewValue: "On", value: "14" },
];

export const SEGMENT_PAYMENT_TYPE = [
  { viewValue: "Debit/Credit Card", value: "1" },
  { viewValue: "Cash", value: "2" },
  { viewValue: "Card on Delivery", value: "4" },
  { viewValue: "Apple Pay", value: "3" },
];

export const SEGMENT_SOURCE_TYPE = [
  // { viewValue: "Android", value: "1" },
  // { viewValue: "IOS", value: "2" },
  { viewValue: "Web", value: "3" },
];
export const SEGMENT_PAYMENT_STATUS = [
  { viewValue: "Completed", value: "completed" },
  { viewValue: "Failed", value: "failed" },
];
export const SEGMENT_SINCE = [
  { viewValue: "Year", value: "1" },
  { viewValue: "Month", value: "2" },
  { viewValue: "Weak", value: "3" },
  { viewValue: "Day", value: "4" },
];
export const PROMO_TYPE = {
  IN_STORE_PROMO: "1",
  GENERAL_PROMO: "2",
};

export const PROMO_TYPES_IN_HTML = {
  "1": "In-Store Promo",
  "2": "General Promo",
};

export const DISCOUNT_TYPE = [
  { viewValue: "Percentage", value: "1" },
  { viewValue: "Amount", value: "2" },
];

export const PROMO_DISCOUNT_TYPE = [
  { viewValue: "Fixed Discount", value: "1" },
  { viewValue: "Discount Percentage", value: "2" },
];

export const PROMO_USE_USER_TYPE = [
  // { viewValue: 'Driver', value: 'driver' },
  // { viewValue: 'User', value: 'user' },
  // { viewValue: 'Guest', value: 'guest' },
  { viewValue: "Existing", value: "existing" },
  { viewValue: "New", value: "new" },
  { viewValue: "Both", value: "both" },
];

export const DISCOUNT = {
  "1": "Percentage",
  "2": "Amount",
};
export const WEEK_DAY = [
  { view: "Monday", value: "Monday" },
  { view: "Tuesday", value: "Tuesday" },
  { view: "Wednesday", value: "Wednesday" },
  { view: "Thursday", value: "Thursday" },
  { view: "Friday", value: "Friday" },
  { view: "Saturday", value: "Saturday" },
  { view: "Sunday", value: "Sunday" },
];

// export const FAQ_TYPE ={

// }

export const ORDER_TYPE = [
  { viewValue: "Pickup", value: "pickup" },
  { viewValue: "Carhop", value: "curbside" },
  { viewValue: "Delivery", value: "delivery" },
  { viewValue: "Dine-In", value: "dinein" },
];

export const NOTIFICATION_REDIRECTION = [
  { viewValue: "URL", value: "url" },
  { viewValue: "Home Page", value: "home" },
  { viewValue: "Item", value: "item" },
  { viewValue: "Category", value: "category" },
  { viewValue: "Coupon", value: "coupon" },
];

export const NOTIFICATION_REDIRECTION_TYPE = [
  { viewValue: "URL", value: "url" },
  { viewValue: "Home Page", value: "home" },
];
export const IN_STORE_ORDER_TYPE = [
  // { viewValue: 'Delivery', value: 'delivery' },
  // { viewValue: 'Curbside', value: 'curbside' },
  { viewValue: "Pickup", value: "pickup" },
];

export const ONLY_DELIVERY_ORDER_TYPE = [
  { viewValue: "Delivery", value: "delivery" },
];

export const PROMO_ORDER_TYPE = [
  { viewValue: "Pickup", value: "3" },
  { viewValue: "Carhop", value: "2" },
  { viewValue: "Delivery", value: "1" },
  { viewValue: "Dine-In", value: "4" },
];

export const MESSAGE_TYPE_NOTIFICATION = [
  // const MESSAGE_TYPE = {
  //   ORDERS_AND_PURCHASE: "1",
  //   PROMOS_AND_OFFERS: "2",
  //   OTHERS: "3",
  // };
  { viewValue: "Orders and Purchase", value: "1" },
  { viewValue: "Promos and Offers", value: "2" },
  { viewValue: "Others", value: "3" },
];

export const ORDER_TYPE_LIST = {
  ["3"]: "Pickup",
  ["4"]: "Dine-In",
  ["2"]: "Carhop",
  ["1"]: "Delivery",
};
export const DRIVER_LIST = {
  ["off"]: "Off Duty",
  ["on"]: "On Duty",
};

export const OFFERS_TYPE = [
  { viewValue: "Discount", value: "1" },
  { viewValue: "Item", value: "2" },
  { viewValue: "Free Delivery", value: "3" },
  { viewValue: "Discounted Delivery", value: "4" },
];

export const IN_STORE_OFFERS_TYPE = [
  { viewValue: "Discount", value: "1" },
  { viewValue: "Item", value: "2" },
];

export const OFFER_TYPE_LIST = {
  ["1"]: "Discount",
  ["2"]: "Item",
  ["3"]: "Free Delivery",
  ["4"]: "Discounted Delivery",
};

export const PROMO_TYPE_LIST = {
  ["1"]: "In-Store",
  ["2"]: "Online Store",
};

export const PLATFORM_LIST = {
  ["1"]: "Android",
  ["2"]: "IOS",
  ['5']:'Huawei'
};
export const UPDATED_LIST = {
  ["1"]: "Normal",
  ["2"]: "Forcefully",
};

export const DISCOUNT_TYPE_LIST = {
  ["1"]: "Fixed Discount",
  ["2"]: "Discount Percentage",
};

export const PROMOS_TYPE = [
  { viewValue: "In-Store", value: "1" },
  { viewValue: "Online Store", value: "2" },
];

export const COUPON_ACTION = {
  ADD: 1,
  EDIT: 2,
  VIEW: 3,
};

export const QUERY_TYPE = [
  { viewValue: "Feedback", value: "feedback" },
  { viewValue: "Enquiry", value: "enquiry" },
  { viewValue: "Complaint", value: "complaint" },
];

export const TYPE_OF_REDIRECTION = [
  { viewValue: "URL", value: "url" },
  { viewValue: "Item", value: "item" },
  { viewValue: "Category", value: "category" },
];

export const POPUP_REDIRECTION = [
  { viewValue: "URL", value: "url" },
  { viewValue: "Item", value: "item" },
  { viewValue: "Category", value: "category" },
  { viewValue: "Coupon", value: "coupon" },
];

export const MEDIA_TYPE = [
  { viewValue: "Image", value: "1" },
  { viewValue: "Video", value: "2" },
  { viewValue: "Gif", value: "3" },
];

export const VIDEO_AND_GIF_MEDIA_TYPE = [
  // { viewValue: "Image", value: "1" },
  { viewValue: "Video", value: "2" },
  { viewValue: "Gif", value: "3" },
];

export const POPUP_CUSTOM_REDIRECTION = [{ viewValue: "URL", value: "url" }];
export const DISPLAY_TYPE = [
  { viewValue: "Home Page", value: "1" },
  { viewValue: "Menu", value: "2" },
  { viewValue: "Cart Page", value: "3" },
  { viewValue: "Order Details", value: "4" },
];
export const DISPLAY_LOCATION = {
  [1]: "Home Page",
  [2]: "Menu",
  [3]: "Cart Page",
  [4]: "Order Details",
};

export const DISPLAY_FREQUENCY = {
  [1]: "One Time",
  [2]: "Every Time",
  [3]: "Custom",
  [4]: "One Time Per Day",
  [5]: "Custom Per Day",
};
export const VISIBILITY_TYPE = [
  { viewValue: "One Time", value: "1" },
  { viewValue: "Every Time", value: "2" },
  { viewValue: "Custom", value: "3" },
  { viewValue: "One Time Per Day", value: "4" },
  { viewValue: "Custom Per Day", value: "5" },
];

export const PAYMENT_TYPE = {
  ["1"]: "Debit/Credit Card",
  ["2"]: "COD (Cash On Delivery)",
  ["3"]: "Apple Pay",
  ["4"]: "Card Machine",
};

export const PAYMENT_STATUS = {
  ["1"]: "Pending",
  ["2"]: "Complete",
  ["3"]: "Refunded",
  ["4"]: "Failed",
};

export const REFUND_STATUS = {
  ["2"]: "Completed",
  ["3"]: "Completed",
  ["4"]: "Failed",
};

export const PAYMENT_STATUS_LIST = [
  { name: "Pending", value: "1" },
  { name: "Complete", value: "2" },
  { name: "Refunded", value: "3" },
  { name: "Failed", value: "4" },
];

export const OFFER_TYPE = [
  { viewValue: "discount offers", value: "1" },
  { viewValue: "combos and deals", value: "2" },
  { viewValue: "multiple coupon code", value: "3" },
  { viewValue: "buy X item and get % discount on that item", value: "4" },
  { viewValue: "buy one get one free", value: "5" },
  { viewValue: "new user discount", value: "6" },
  { viewValue: "special offers", value: "7" },
  { viewValue: "complimentary items", value: "8" },
];

export const OFFER = {
  "1": "Discount offers",
  "2": "Combos and deals",
  "3": "Multiple coupon code",
  "4": "Buy X item and get % discount on that item",
  "5": "Buy one get one free",
  "6": "new user discount",
  "7": "special offers",
  "8": "complimentary items",
};

// export const MODULE_NAME = {
//   'customer-management': 'ABS_USER',
//   'sub-admins': 'ABS_SUB_ADMINS',
//   'dashboard': 'ABS_DASHBOARD',
//   'feedback': 'ABS_FEEDBACK',
//   'report-analyitics': 'ABS_REPORT_ANALYTICS',
//   'menu': 'ABS_MENU',
//   'order': 'ABS_ORDERS',
//   'payment': 'ABS_PAYMENTS',
//   'promo-&-offer': 'ABS_PROMO_OFFER',
//   'loyalty': 'ABS_LOYALITY',
//   'restaurants': 'ABS_RESTRO',
//   'notifications': 'ABS_NOTIFICATION',
//   'cms': 'ABS_CMS',
//   'general': 'ABS_GENERAL_SETTING',
//   'youLike': 'ABS_YOU_MAY_ALSO_LIKE',
//   'recommendation': 'ABS_RECOMMENATION',
// };

export const MODULE_ID = {
  dashboard: 1,
  customer: 2,
  subAdmins: 3,
  restaurants: 4,
  feedback: 13,
  report: 12,
  menu: 7,
  order: 5,
  payment: 6,
  promo: 11,
  loyalty: 10,
  notifications: 9,
  cms: 8,
  general: 16,
  youLike: 15,
  recommendation: 14,
  location: 17,
  version: 18,
  generic: 19,
  activity_log: 20,
  bottom: 22,
  globalPayment: 23,
  event: 21,
  partnership: 24,
  stamp: 25,
  driver: 26,
  home: 27,
  dineIn: 28,
  popup: 29,
  segment: 30,
};

export const NOTIFICATION_STATUS = {
  SENT: "SENT",
  DRAFTED: "delivered",
  SCHEDULED: "scheduled",
  RESCHEDULED: "rescheduled",
};

export const NOTIFICATION_STATUS_LIST = [
  { name: "Delivered", value: NOTIFICATION_STATUS.DRAFTED },
  { name: "Scheduled", value: NOTIFICATION_STATUS.SCHEDULED },
  { name: "Rescheduled", value: NOTIFICATION_STATUS.RESCHEDULED },
];

export const NOTIFICATION_STATUS_IN_ADD = [
  { name: "Push", value: "push" },
  { name: "Email", value: "email" },
  { name: "In-App", value: "in-app" },
  { name: "SMS", value: "sms" },
];

// const NOTIFICATION_SCENARIO_TYPE = {
//   PERCENTAGE_DISCOUNT: "1",
//   FIXED_DISCOUNT_AMOUNT: "2",
//   OFFERED_ITEMS: "3",
//   EVENT_BASED_SEPCIAL_OFFER: "4",
//   LIMITED_USER_OFFER: "5",
//   ORDER_DISPATCHED_BY_STORE: "6",
//   ORDER_ACCEPTED_BY_STORE: "7",
//   ORDER_DELIVERED: "8",
//   PAYMENT_FAILURE: "9",
//   PAYMENT_SUCCESSFUL: "10",
//   CANCEL_ORDER_ON_USER_REQUEST: "11",
//   // ITEM_ADDED_CART_REMINDER:"12",
// };

export const NOTIFICATION_SCENARIO_TYPE = [
  { name: "Percentage Discount", value: "1" },
  { name: "Fixed Discount Amount", value: "2" },
  { name: "Offered Items", value: "3" },
  { name: "Event Based Special Offer", value: "4" },
  { name: "Limited User Offer", value: "5" },
  { name: "Order Dsipatched By Store", value: "6" },
  { name: "Order Accepted By Store", value: "7" },
  { name: "Order Delivered", value: "8" },
  { name: "Payment Failure ", value: "9" },
  { name: "Payment Successful ", value: "10" },
  { name: "Cancel Order On User Request ", value: "11" },
];

export const NOTIFICATION_SCENARIO_TYPE_IN_LIST = {
  ["1"]: "Percentage Discount",
  ["2"]: "Fixed Discount Amount",
  ["3"]: "Offered Items",
  ["4"]: "Event Based Special Offer",
  ["5"]: "Limited User Offer",
  ["6"]: "OrderDsipatched By Store",
  ["7"]: "Order Accepted By Store",
  ["8"]: "Order Delivered",
  ["9"]: "Payment Failure ",
  ["10"]: "Payment Successful",
  ["11"]: "Cancel OrderOn User Request ",
};

export const MOD_TYPE = [
  { view: "Drink", value: "drink" },
  { view: "Add", value: "add" },
  { view: "Remove", value: "remove" },
  { view: "Replace", value: "replace" },
];

export const DRINK_SIZE = [
  { view: "Large", value: "3" },
  { view: "Medium", value: "2" },
  { view: "Regular", value: "1" },
];

export const DRINK_SIZE_ARABIC = [
  { view: "كبير", value: "3" },
  { view: "متوسط", value: "2" },
  { view: "عادي", value: "1" },
];

/**
 * @ROLE_MANAGEMENT_RELATED_STUFF
 */
export const MODULE_ID_OF = {
  DASHBOARD: 1,
  USER: 2,
  SUB_ADMIN: 3,
  DRIVER: 26,
  RESTURANT: 4,
  ORDER: 5,
  PAYMENT: 6,
  MENU: 7,
  CMS: 8,
  BROADCAST_NOTIFICATION: 9,
  LOYALITY: 10,
  PROMO: 11,
  REPORT: 12,
  FEEDBACK: 13,
  MAY_YOU_ALSO_LIKE: 15,
  RECOMMENDATION: 14,
  POP_UP: 29,
  SEGMENT: 30,
  DINE_IN: 28,
  GENERAL: 16,
  LOCATION: 17,
  VERSION: 18,
  GENERIC: 19,
  BOTTOM: 22,
  GLOBAL_PAYMENT: 23,
  EVENT: 21,
  PARTNERSHIP: 24,
  STAMP: 25,
  HOME: 27,
  ACTIVITY_LOG: 20,
};

export const MANAGE_TYPE_OF = {
  BANNERS: "Banner Management",
  BROADCAST_NOTIFICATIONS: "Notification Management",
  CM_S: "Content Management",
};

export const DAYS_LIST = [
  { day: "Everyday", value: "Everyday" },
  // { day: 'Weekend', value: 'weekend' },
  { day: "Monday", value: "Monday" },
  { day: "Tuesday", value: "Tuesday" },
  { day: "Wednesday", value: "Wednesday" },
  { day: "Thursday", value: "Thursday" },
  { day: "Friday", value: "Friday" },
  { day: "Saturday", value: "Saturday" },
  { day: "Sunday", value: "Sunday" },
];

export const PLATFORM = [
  { value: "1", viewValue: "Android" },
  { value: "2", viewValue: "IOS" },
  { value: "5", viewValue: "Huawei" },

];
export const UPDATE_TYPE = [
  { value: "1", viewValue: "Normal" },
  { value: "2", viewValue: "Forcefully" },
];

export const AVAILABLE_DAYS_LIST = [
  { dayName: "Monday", value: "Monday" },
  { dayName: "Tuesday", value: "Tuesday" },
  { dayName: "Wednesday", value: "Wednesday" },
  { dayName: "Thursday", value: "Thursday" },
  { dayName: "Friday", value: "Friday" },
  { dayName: "Saturday", value: "Saturday" },
  { dayName: "Sunday", value: "Sunday" },
];

export const PERMISSIONS = [
  // { module: MANAGE_TYPE_OF.BANNERS, moduleId: MODULE_ID_OF.BANNERS },
  {
    module: MANAGE_TYPE_OF.BROADCAST_NOTIFICATIONS,
    moduleId: MODULE_ID_OF.BROADCAST_NOTIFICATION,
  },
  { module: MANAGE_TYPE_OF.CM_S, moduleId: MODULE_ID_OF.CMS },
];
