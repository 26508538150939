import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';
import { userId, permission, email, couponTime } from '@constants/storage-keys';
import { Router } from '@angular/router';
import {
  LOCATION_API, LOCATION_ZONE_API,
  MENU_TEMPLATE_API, USER_API,
  USER_INFO_API
} from '@constants/api-end-point';
import * as routes from '@constants/absolute-route';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '@components/toast-notification/toast.service';
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  interval;
  time: number = 60;
  profileDetail: any;
  menuTempList: any;
  resurantLocationList: any;
  userList: any;
  zoneLocationList: any;
  showError = new BehaviorSubject(false);

  constructor(
    private _http: HttpService,
    private _router: Router,
    private _toast: ToastService,

  ) {
  }

  setUserToken(token) {
    localStorage.setItem('session_token', token);
  }

 

  startInterval() {
    this.showError.next(true);
    this.interval = setInterval(() => {
      if (this.time > 0) {
        this.time -= 1;
        localStorage.setItem(couponTime, JSON.stringify(this.time))
      } else {
        this.clearMethod();
      }
    }, 1000);
  }

  clearMethod() {
    clearInterval(this.interval);
    this.showError.next(false);
    localStorage.removeItem(couponTime);
    this._toast.success('We have accepted your request to create coupon,  coupon will be available soon');
    this.time = 60;
  }

  get token() {
    return localStorage.getItem("session_token");
  }


  get userId() {
    return atob(localStorage.getItem(userId));
  }

  get emailId() {
    return atob(localStorage.getItem(email));
  }

  logout() {
    localStorage.clear();
    setTimeout(() => {
      this.profileDetail = null;
    }, 1000); // this is used for avoid random reflection on side bar if logout
    this._router.navigate([routes.ABS_ACCOUNT_LOGIN]);
  }

  loginSuccessfully(response: any, responeFrom = '') {
    const routing = routes[response.data.adminData.permission[0].router];
    this.setUserToken(response.data.adminData.accessToken);
    localStorage.setItem(userId, btoa(response.data.adminData._id));
    localStorage.setItem(permission, btoa(response.data.adminData.permission));
    this._router.navigate([routing]);
  }

  getProfileDetail(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.profileDetail) {
        resolve(this.profileDetail);
      } else {
        this._http.get(USER_INFO_API, '', showLoader).toPromise().then((response: any) => {
          this.profileDetail = response.data;
          resolve(this.profileDetail);
        }, error => {
          reject(error);
        });
      }
    });
  }

  getMenuTemplate(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.menuTempList) {
        resolve(this.menuTempList);
      } else {
        this._http.get(MENU_TEMPLATE_API, '', showLoader).toPromise().then((response: any) => {
          this.menuTempList = response.data;
          resolve(this.menuTempList);
        }, error => {
          reject(error);
        });
      }
    });
  }

  getResturantLocation(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.resurantLocationList) {
        resolve(this.resurantLocationList);
      } else {
        this._http.get(LOCATION_API, '', showLoader).toPromise().then((response: any) => {
          this.resurantLocationList = response.data;
          resolve(this.resurantLocationList);
        }, error => {
          reject(error);
        });
      }
    });
  }

  getZoneLocation(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.zoneLocationList) {
        resolve(this.zoneLocationList);
      } else {
        this._http.get(LOCATION_ZONE_API, '', showLoader).toPromise().then((response: any) => {
          this.zoneLocationList = response.data;
          resolve(this.zoneLocationList);
        }, error => {
          reject(error);
        });
      }
    });
  }

  getUserList(showLoader = true, searchKey?: string) {
    return new Promise((resolve, reject) => {
      if (this.userList) {
        resolve(this.userList);
      } else {
        const params = {
          pageNo: 1,
          limit: 10,
          status: 'active'
        }
        if (searchKey) {
          params['searchKey'] = searchKey
        }
        this._http.get(USER_API, params, showLoader).toPromise().then((response: any) => {
          this.userList = response.data;

          resolve(this.userList);
        }, error => {
          reject(error);
        });
      }
    });
  }

  deviceDetail(info?: number) {
    /*---1=device_token, 2=deviceId, 3=platform---*/
    switch (info) {
      case 1:
        let deviceToken = this.attachDeviceToken();
        return deviceToken;
      case 2:
        let deviceId = this.randomDeviceId();
        return deviceId;
      case 3:
        return "3";
      case 4:
        return this.getTimeZone();
    
    }
  }

  attachDeviceToken() {
    const val = Math.random();
    return (Date.now() + Math.floor(val * 1000000) + 1).toString();
  }

  randomDeviceId() {
    return (window.navigator.userAgent.replace(/\D+/g, ''));
  }

  getTimeZone() {
    let date = new Date();
    let offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }

}
