export const infoKey = btoa('phoneInfo');
export const forgotScreen = btoa('forgot');
export const userId = btoa('userId');
export const email = btoa('email');
export const permission = btoa('permission');
export const sessionId = btoa('sessionId');
export const couponTime = btoa('couponTime');









